
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Error",
  setup() {
      const router = useRouter();
     function handle(){
        router.push({name: "Index"})
     }
    return {
      handle
    };
  },
});
